
  import { Component, Mixins, Prop } from 'vue-property-decorator';
  import { Mutation } from 'vuex-class';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import { Insight, NotStrictInsight } from '@/types/entities';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import HipsterInput from '@/components/HipsterInput.vue';
  import ImageUpload from '@/components/ImageUpload.vue';
  import {ADD_TAG, REMOVE_TAG, SET_INSIGHT_ITEM_VALUE} from '@/store/insights';
  import slug from 'slug';
  import ImagesMixin from '@/mixins/images.mixin';
  import ContentEditor from '@/components/ContentEditor.vue';

  @Component({
    components: { ImageUpload, HipsterInput, HipsterSelector, InspiniaIbox, ContentEditor },
    mixins: [ validationMixin ],
    validations() {
      return {
        insight: {
          title: { required },
          content: { required },
          meta_title: { required },
          meta_description: { required },
          slug: { required },
          author: { required }
        }
      };
    },
    data() {
      return {
        isVueEditor: false
      };
    }
  })
  export default class InsightEdit extends Mixins(ImagesMixin) {
    @Prop() insight: Insight;

    @Mutation(SET_INSIGHT_ITEM_VALUE) setInsightValue: (data: NotStrictInsight) => void;
    @Mutation(ADD_TAG) addTag: (tag: string) => void;
    @Mutation(REMOVE_TAG) removeTag: (tag: string) => void;

    public setTitle(value: string) {
      this.setInsightValue({
        title: value,
        slug: slug(value),
        meta_title: value
      });
    }

    public setAuthor(value: string) {
      this.setInsightValue({ author: value });
    }

    public checkValidation() {
      this.$v.$touch();

      return !this.$v.$error;
    }
  }
